/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'three-dots': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M3 9.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3m5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3m5 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3"/>',
    },
});
